/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Helmet from "react-helmet"
import Feedback from "./feedback"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          disclaimer
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link href="https://vjs.zencdn.net/7.7.5/video-js.css" rel="stylesheet" />
        <script defer src="https://use.fontawesome.com/releases/v5.3.1/js/all.js"></script>
        <script data-ad-client="ca-pub-8300716136915670" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>

      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>

      <section className="section">
        <div className="container">
          <Feedback />
        </div>
      </section>

      <footer className="footer">
        <div className="content has-text-left">{data.site.siteMetadata.disclaimer}</div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

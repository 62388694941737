import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

function Header() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <header>
      <nav className="navbar is-light" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            {site.siteMetadata.title}
          </a>

          <label role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" htmlFor="nav-toggle-state">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </label>
        </div>
        <input type="checkbox" id="nav-toggle-state" />

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link className="navbar-item" activeClassName="is-active" to="/">
              Forside
            </Link>

            <Link className="navbar-item" activeClassName="is-active" to="/operationer">
              Operationer
            </Link>

            <Link className="navbar-item" activeClassName="is-active" to="/sygdomme-i-underlivet">
              Sygdomme i underlivet
            </Link>

            <Link className="navbar-item" activeClassName="is-active" to="/graviditet-foedsel-og-barsel">
              Graviditet, fødsel og barsel
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

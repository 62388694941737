// import React from "react"

const Ad = () => {
  return null
  // (
  //   <div className="tile is-ancestor">
  //     <div className="tile is-parent">
  //       <article className="tile is-child notification is-link has-text-centered">ANNONCE</article>
  //     </div>
  //   </div>
  // )
}

export default Ad

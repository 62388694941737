import React, { useState } from "react"
import axios from "axios"

const Feedback = () => {
  const [visible, setVisible] = useState(false)
  const [ack, setAck] = useState(false)

  function showForm() {
    setVisible(true)
    setAck(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const data = new FormData(e.target)

    const feedback = data.get("feedback")

    axios({
      method: "post",
      url: "https://app.99inbound.com/api/e/bmHtN6so",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        feedback,
      },
    }).catch(err => {
      console.log(err)
    })

    setVisible(false)
    setAck(true)
  }

  const form = (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">Feedback</label>
          <div className="control">
            <textarea className="textarea is-light" name="feedback" placeholder="Feedback"></textarea>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-link">Send feedback</button>
          </div>
        </div>
      </form>
    </div>
  )

  const thanks = <p className="notification is-light">Mange tak for dit feedback.</p>

  return (
    <div className="tile is-ancestor">
      <div className="tile is-parent">{/* <article className="tile is-child notification is-link">ANNONCE</article> */}</div>
      <div className="tile is-parent is-4">
        <article className="tile is-child box">
          <p className="title">Feedback</p>
          <div className="content">
            <p>
              Jeg sætter stor pris på tilbagemeldinger. Har du forslag til emner, der mangler at blive belyst, spørgsmål, som mangler at blive besvaret eller opdaget fejl, så vil jeg sætte stor pris på din tilbagemelding{" "}
              <button className="button-link" onClick={() => showForm()}>
                her
              </button>{" "}
              .
            </p>
            {visible ? form : null}
            {ack ? thanks : null}
          </div>
        </article>
      </div>
    </div>
  )
}

export default Feedback

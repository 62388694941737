import React from "react"
import { Link } from "gatsby"

const Breadcrumb = ({ items = [] }) => {
  const current = items.slice(-1)
  const skip = items.slice(0, -1)

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/">Forside</Link>
        </li>
        {/* <BreadcrumbLink text="Forside" link="/" /> */}

        {skip.map((item, i) => (
          <li key={i}>
            <Link to={item[1]}>{item[0]}</Link>
          </li>
        ))}

        <li className="is-active">
          <a href={current[0][1]} aria-current="page">
            {current[0][0]}
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Breadcrumb
